import {
    Button,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    withStyles,
} from '@material-ui/core';
import React, {useCallback} from 'react';
import '../Style/table.css';
import qs from 'qs'
import {withSnackbar} from 'notistack'
import {MoreHorizRounded} from "@material-ui/icons";
import Drawer from '@material-ui/core/Drawer';
import Label from "../Label/Label";
import axios from "axios";
import urls from "../urls";
import SearchIcon from '@material-ui/icons/Search';
import AppBar from '@material-ui/core/AppBar';


class TableView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            query:'',
            isSelect: false,
            selectedStatus: [],
            status: '',
            id: '',
            assigned_service_eng: '',
            serviceEngineersValue: 1,
            isDrawerOpen: false,
            singleComplaint: [],
            loading: false,
            message:'',
        }
    }

    openSelect = (event, selectStatus) => {
        event.preventDefault();
        this.setState({
            isSelect: true,
            value: '',
            selectStatus
        })
    }
    handleChange = (event, singleComplaint) => {

        this.setState({
            status: event.target.value,
            id: parseInt(singleComplaint.id),
            assigned_service_eng: singleComplaint.assigned_service_eng
        })
        let requestBody = {}
        requestBody = {
            id: singleComplaint.id,
            status: event.target.value,
            assigned_service_eng: singleComplaint.assigned_service_eng
        }
        axios.post(urls.baseURL+"/executives/edit_complain", qs.stringify(requestBody), {
            headers: {
                Authorization: 'Bearer ' + urls.accessToken
            }
        }).then(res => {
            this.props.enqueueSnackbar('Status Changed Successfully!', {
                variant: 'success',
            });
            this.props.updateTableData();
        }).catch(error => {
            this.props.enqueueSnackbar('Something Went Wrong!', {
                variant: 'error',
            });
        })
    }

    handleChangeEngineer = (event, singleComplaint) => {
        this.setState({
            status: (singleComplaint.status),
            id: parseInt(singleComplaint.id),
            assigned_service_eng: event.target.value
        })
        let requestBody = {}
        requestBody = {
            id: singleComplaint.id,
            status: singleComplaint.status,
            assigned_service_eng: event.target.value
        }

        axios.post(urls.baseURL+"/executives/edit_complain", qs.stringify(requestBody), {
            headers: {
                Authorization: 'Bearer ' + urls.accessToken,
            }
        }).then(res => {
            this.props.enqueueSnackbar('Assigned Engineer Changed Successfully!', {
                variant: 'success',
            });
            this.props.updateTableData();
        }).catch(error => {
            this.props.enqueueSnackbar('Something Went Wrong!', {
                variant: 'error',
            });
        })
    }

    handleClose = () => this.setState({isSelect: false})

    handleOpen = () => this.setState({isSelect: true})

    handleDrawerOpen = (event, singleComplaint) => {
        event.preventDefault();
        console.log(singleComplaint);
        this.setState({isDrawerOpen: true, singleComplaint})
    }

    handleDrawerClose = () => this.setState({isDrawerOpen: false})

    handleOnInputChange=(evt)=> {
        this.props.searchString(evt.target.value)
    }

    render() {
       //const {query} = this.state;
        const StyledTableCell = withStyles((theme) => ({
            head: {
                backgroundColor: '#B8BFC2',
                color: theme.palette.common.black,
            },
            body: {
                fontSize: 14,
            },
        }))(TableCell)
        return (
            <div className='root'>
                <div className="container">
                        <h2  className="heading"> Repos Service Dashboard </h2>
                        <label className="search-label" htmlFor="search-input">
                            <input
                                type="text"
                                id="search-input"
                                className="search-input"
                                placeholder="Search.."
                                onChange={this.handleOnInputChange}
                            />
                            <i className="fa fa-search  search-txt" aria-hidden="true"></i>
                        </label>
                </div>
            <div>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align='Center'>Complaint ID</StyledTableCell>
                            <StyledTableCell align='Center'>Complaint Logging Date</StyledTableCell>
                            <StyledTableCell align='Center'>Concern Person</StyledTableCell>
                            <StyledTableCell align='Center'>Mobile</StyledTableCell>
                            <StyledTableCell align='Center'>RPP</StyledTableCell>
                            <StyledTableCell align='Center'>Issue Category</StyledTableCell>
                            <StyledTableCell align='Center'>Issue Subcategory</StyledTableCell>
                            <StyledTableCell align='Center'>Assigned</StyledTableCell>
                            <StyledTableCell align='Center'>Select Engineer</StyledTableCell>
                            <StyledTableCell align='Center'>Status</StyledTableCell>
                            <StyledTableCell align='Center'>Change Status</StyledTableCell>
                            <StyledTableCell align='Center'>More</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    {
                        this.props.isEmpty ?
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={13}>
                                        <div style={{textAlign: "center"}}>
                                            <img src={'/assets/no_data2.gif'}/>
                                            <h2>No service requests!</h2>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody> :
                            <TableBody>
                                {
                                    this.props.data.map(singleComplaint => (
                                        <TableRow hover role="checkbox">
                                            <TableCell align='Center' style={{ fontWeight: 'bold' }}>{singleComplaint.service_number}</TableCell>
                                            <TableCell align='Center' >{singleComplaint.created_at}</TableCell>
                                            <TableCell align='Center' >{singleComplaint.contact_person_name}</TableCell>
                                            <TableCell align='Center' >{singleComplaint.mobile}</TableCell>
                                            <TableCell align='Center' >{singleComplaint.refueler_reg_number}</TableCell>
                                            <TableCell align='Center' >{singleComplaint.issue_category}</TableCell>
                                            <TableCell align='Center'>
                                                {singleComplaint.issue_subcategory ?
                                                    singleComplaint.issue_subcategory : 'NA'}</TableCell>
                                            <TableCell align='Center' style={ singleComplaint.assigned_service_eng === null ? { fontStyle: 'italic' } : { fontWeight: 'bold' } }>
                                                {
                                                 singleComplaint.assigned_service_eng === null ?
                                                'Not Assigned' : singleComplaint.assigned_service_eng
                                                }
                                            </TableCell>
                                            <TableCell align='Center'>
                                                <Select
                                                    onClick={this.handleClose}
                                                    onOpen={this.handleOpen}
                                                    variant={"outlined"}
                                                    label={"Select Engineer"}
                                                    value={'Change'}
                                                    onChange={event => this.handleChangeEngineer(event, singleComplaint)}
                                                >
                                                    <MenuItem value="Change">
                                                        <em>Select</em>
                                                    </MenuItem>
                                                    {
                                                        this.props.serviceEngineersList.length === 0 ?
                                                            <MenuItem value={"Select Engineer"}>{"Select Engineer"}</MenuItem> :
                                                            this.props.serviceEngineersList.map(singleEngineer => (
                                                                <MenuItem value={singleEngineer}>{singleEngineer}</MenuItem>
                                                            ))
                                                    }
                                                </Select>
                                            </TableCell>
                                            <TableCell align='Center' >
                                                <Label
                                                    variant={"contained"}
                                                    style={{color:'black'}}
                                                    color={
                                                        singleComplaint.status === "OPN" ?
                                                            "#ff5722" : singleComplaint.status === "INP" ?
                                                            "#ffb74d" : singleComplaint.status === "CAN" ?
                                                                "#757575" : singleComplaint.status === 'COM' ?
                                                                    "#66bb6a" : 'grey'
                                                    }>{singleComplaint.status === "OPN" ?
                                                    "OPEN" : singleComplaint.status === "INP" ?
                                                        "IN-PROGRESS" : singleComplaint.status === "CAN" ?
                                                            "CANCEL" : singleComplaint.status === 'COM' ?
                                                                "COMPLETE" : singleComplaint.status === ''?
                                                                    "NA":singleComplaint.status}
                                                </Label>
                                            </TableCell>
                                            <TableCell align='Center'>
                                                <Select
                                                    labelId="demo-controlled-open-select-label"
                                                    id="id"
                                                    variant={"outlined"}
                                                    onClick={this.handleClose}
                                                    onOpen={this.handleOpen}
                                                    label={"Status"}
                                                    value={"Change"}
                                                    onChange={event => this.handleChange(event, singleComplaint)}
                                                >
                                                    <MenuItem value="Change">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={"OPN"}>OPEN</MenuItem>
                                                    <MenuItem value={"CAN"}>CANCEL</MenuItem>
                                                    <MenuItem value={"COM"}>COMPLETE</MenuItem>
                                                    <MenuItem value={"INP"}>IN-PROGRESS</MenuItem>
                                                </Select>
                                            </TableCell>
                                            <TableCell align='Center'>
                                                <Button
                                                    onClick={event => this.handleDrawerOpen(event, singleComplaint)}
                                                >
                                                    <MoreHorizRounded/>
                                                </Button>

                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                    }
                    <Drawer
                        open={this.state.isDrawerOpen}
                        anchor={"right"}
                        onClose={this.handleDrawerClose}
                    >
                        <div>
                            <Table>
                                <TableRow>
                                    <TableCell align='Left' style={{ fontWeight: 'bold' }}> Pump Name </TableCell>
                                    <TableCell align='Right'>{this.state.singleComplaint.partner_name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='Left' style={{ fontWeight: 'bold' }}>OMC Name</TableCell>
                                    <TableCell align='Right'>{this.state.singleComplaint.omc_name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='Left' style={{ fontWeight: 'bold' }}>Zone</TableCell>
                                    <TableCell align='Right'>{this.state.singleComplaint.zone}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='Left' style={{ fontWeight: 'bold' }}>Region</TableCell>
                                    <TableCell align='Right'>{this.state.singleComplaint.region}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>
                                        Issue Description
                                    </TableCell>
                                    <TableCell>

                                        <p style={{width: '176px', margin: 0, padding: 0}}>
                                            {this.state.singleComplaint.issue_description ?
                                                this.state.singleComplaint.issue_description : 'NA'}
                                        </p>


                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <div>

                                    </div>
                                </TableRow>
                            </Table>
                        </div>
                    </Drawer>
                </Table>
            </div>
            </div>
        )
    }
}

export default withSnackbar(TableView)

