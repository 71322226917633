import React from 'react';
import TableView from "../Table/TableView";
import CircularProgress from '@material-ui/core/CircularProgress';
import {withSnackbar} from 'notistack';
import axios from "axios";
import urls from '../urls'

class ServiceRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            serviceEngineers: [],
            isLoading: false,
            isTableEmpty: false,
            dataCopy:[]
        }
    }

    fetchRequest() {
        this.setState({
            isLoading: true,
        })
        axios.get(urls.baseURL+"/executives/complain/all", {
            headers: {
                Authorization: 'Bearer ' + urls.accessToken
            },
        }).then(res => {
            if (res.data.data.count === 0){
                this.setState({
                    isLoading: false,
                    isTableEmpty: true
                })
            }
            else {
                this.setState({
                    isLoading: false,
                    data: res.data.data.results,
                    dataCopy: res.data.data.results
                })
            }

        }).catch(() => this.props.enqueueSnackbar('Failed fetching data.'))
    }

    filterData = (value) =>{
        let searchKey = value.toLowerCase()
        let json = this.state.dataCopy
        const search = json.filter((json) => (json.contact_person_name.toLowerCase().includes(searchKey) ||
            json.mobile.toString().toLowerCase().includes(searchKey) ||
            json.service_number.toString().toLowerCase().includes(searchKey) ||
            json.partner_name.toLowerCase().includes(searchKey) ||
            json.refueler_reg_number.toString().toLowerCase().includes(searchKey)));
        if (value===""){
            this.setState({
                data: this.state.dataCopy
            })
        }else {
            this.setState({
                data: search
            })
        }
    }

    fetchServiceEng() {
        axios.get(urls.baseURL+"/executives/get_service_eng/all", {
            headers: {
                Authorization: 'Bearer ' + urls.accessToken
            },
        }).then(res => {
            let data = JSON.stringify(res)
            let parsedObject = JSON.parse(data)
            this.setState({serviceEngineers: parsedObject.data})
        }).catch(() => this.props.enqueueSnackbar('Failed fetching data.'))
    }

    updateTable = () => {
        this.fetchRequest()
    }

    componentDidMount() {
        this.fetchRequest();
        this.fetchServiceEng();
    }

    render() {
        return (
            <div>
                {
                    this.state.isLoading ? <CircularProgress disableShrink={this.state.data}/> :
                        <TableView searchString={this.filterData} data={this.state.data} isEmpty={this.state.isTableEmpty} updateTableData={this.updateTable}
                                   serviceEngineersList={this.state.serviceEngineers}/>
                }
            </div>
        )
    }
}

export default withSnackbar(ServiceRequest);
