import ServiceRequest from './components/ServiceRequest/serviceRequest'
import { withSnackbar } from 'notistack';


function App() {
  return (
    <div className="App">
        <ServiceRequest/>
    </div>

  );
}

export default withSnackbar(App);
